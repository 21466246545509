import React from 'react';
import Button from 'react-bootstrap/Button';
import { ReactComponent as BackWidget } from 'Icons/BackWidget.svg';

class ScrollView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			title: props.title,
			titleComponent: props.titleComponent,
			backButton: props.backButton,
			accessoryButton: props.accessoryButton,
			headerMode: props.headerMode
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			title: nextProps.title,
			titleComponent: nextProps.titleComponent,
			backButton: nextProps.backButton,
			accessoryButton: nextProps.accessoryButton
		};
	}

	render() {
		let backButton;
		if (this.state.backButton) {
			backButton = (<Button variant='link' className='backButton' onClick={ this.state.backButton }><div className='backWidget'><BackWidget /></div><div className='backText'>Back</div></Button>);
		}

		let accessoryButton;
		if (this.state.accessoryButton) {
			accessoryButton = this.state.accessoryButton;
		}

		let title;
		if (this.state.titleComponent) {
			title = this.state.titleComponent;
		} else {
			title = this.state.title;
		}

		let titleView;
		if (backButton || accessoryButton) {
			if (!backButton) {
				backButton = <div>&nbsp;</div>;
			}
			if (!accessoryButton) {
				accessoryButton = <div>&nbsp;</div>;
			}

			titleView = <div className='ScrollTitle triple'>
				<div className='left'>{ backButton }</div>
				<div className='middle'>{ title }</div>
				<div className='right'>{ accessoryButton }</div>
			</div>;
		} else {
			titleView = <div className='ScrollTitle triple'>
				<div className='middle'>{ title }</div>
			</div>;
		}

		let className = 'ScrollWrapper';
		switch (this.state.headerMode) {
		case 'never':
		case 'hide':
		case 'hidden':
			className += ' ScrollHeaderNever';
			break;
		case 'mobile-only':
		case 'mobileOnly':
		case 'small':
			className += ' ScrollHeaderMobile';
			break;
		default:
			className += ' ScrollHeaderAlways';
			break;
		}

		return (<div className={ className }>
			{ titleView }
			<div className='ScrollView'>{ this.props.children }</div>
		</div>);
	}
}

export default ScrollView;