import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CurrentToken, CurrentUserEmail, CredentialsInLongTerm, StoreCredentials, APIBase } from './helpers';
import axios from 'axios';
import qs from 'querystring';
import { Redirect } from 'react-router-dom';

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: CurrentUserEmail(),
			password: '',
			modalVisible: false,
			rememberUser: CredentialsInLongTerm()
		};
		
		this.tryLogin = this.tryLogin.bind(this);
		this.handleUsernameChange = this.handleUsernameChange.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.handleModalDismiss = this.handleModalDismiss.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleRememberCheck = this.handleRememberCheck.bind(this);
	}
	
	handleUsernameChange(event) {
		this.setState({username: event.target.value});
	}
	
	handlePasswordChange(event) {
		this.setState({password: event.target.value});
	}
	
	tryLogin(event) {
		let username = this.state.username.trim();
		let password = this.state.password;
		let remember = this.state.rememberUser;
		
		if (username === '' || password === '') {
			this.setState({modalVisible: true});
			return;
		}
		
		axios.post(APIBase() + '/login', qs.stringify({ "username": username, "password": password })).then((response) => {
			let token = response.data.session_id;
			let user_id = response.data.user_id;
			
			console.log(remember);
			StoreCredentials(token, user_id, username, remember);
			
			this.props.history.push('/voicemail');
		}).catch((error) => {
			this.setState({modalVisible: true});
		});
	}
	
	handleModalDismiss(event) {
		this.setState({modalVisible: false});
	}
	
	handleSubmit(event) {
		event.preventDefault();
	}
	
	handleRememberCheck(event) {
		this.setState({'rememberUser': event.target.checked});
	}
	
	render() {
		if (CurrentToken() !== null) {
			return (<Redirect to="/" />);
		}
		
		return (<div>
			<div className='loginWrapper'>
				<div className='loginLogoSpace'>PhoneAndFax.net</div>
				<div className='loginForm'>
					<Form onSubmit={this.handleSubmit}>
						<Form.Group controlId="usernameField">
							<Form.Label>E-mail</Form.Label>
							<Form.Control type="email" autoComplete="email" placeholder="E-mail" value={this.state.username} onChange={this.handleUsernameChange} required />
						</Form.Group>
						<Form.Group controlId="passwordField">
							<Form.Label>Password</Form.Label>
							<Form.Control type="password" placeholder="Password" value={this.state.password} onChange={this.handlePasswordChange} required />
						</Form.Group>
						<Form.Check className='my-3' type='checkbox' onChange={this.handleRememberCheck} label='Remember me on this device' checked={ this.state.rememberUser } />
						<div className='text-center'><Button type="submit" onClick={this.tryLogin}>Log In</Button></div>
					</Form>
				</div>
			</div>
			<Modal show={this.state.modalVisible}>
				<Modal.Body>
					<p>Your email or password was not correct.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={this.handleModalDismiss}>Ok</Button>
				</Modal.Footer>
			</Modal>
		</div>);
	}
}

export default Login;