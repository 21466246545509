import React from 'react';
import VoicemailContainer from './VoicemailContainer.js';
import axios from 'axios';
import { Redirect, Route, Switch, Link, NavLink } from 'react-router-dom';
import { CurrentToken, CurrentUserID, CurrentUserEmail, StoreCredentials, ClearToken, CredentialsInLongTerm, APIBase } from './helpers';
import LoadingView from './LoadingView';
import GreetingsContainer from './GreetingsContainer.js';
import SettingsView from './SettingsView';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			token: null
		};

		this.goHome = this.goHome.bind(this);
	}
	
	componentDidMount() {
		let token = CurrentToken();
		if (token !== null) {
			this.loadingRequest = axios.get(APIBase() + '/session?token=' + encodeURIComponent(token)).then((response => {
				this.loadingRequest = null;
				
				let token = response.data.session_id;
				let user_id = response.data.user_id;
				let email = response.data.email;
				
				if (token !== CurrentToken() || user_id !== CurrentUserID() || email !== CurrentUserEmail()) {
					StoreCredentials(token, user_id, email, CredentialsInLongTerm());
				}
				
				this.setState({'loading': false, 'token': token});
			})).catch((error) => {
				this.loadingRequest = null;
				console.log(error);
				ClearToken();
				this.setState({'loading': false});
			});
		} else {
			this.setState({'loading': false});
		}
	}
	
	componentWillUnmount() {
		if (this.loadingRequest) {
			this.loadingRequest.cancel();
			this.loadingRequest = null;
		}
	}

	goHome() {
		this.props.history.push('/');
	}
	
	render() {
		if (this.state.loading) {
			return (<LoadingView caption='Checking login…' />);
		} else if (this.state.token === null) {
			return (<Redirect to={{
				pathname: '/login',
				state: { referrer: this.props.location }
			}} />);
		} else if (this.props.history.location.pathname === '/logout') {
			ClearToken();
			return (<Redirect to={{
				pathname: '/login',
				state: { referrer: this.props.location }
			}} />);
		} else if (this.props.history.location.pathname === '/') {
			return (<Redirect to={{
				pathname: '/voicemail',
				state: { referrer: this.props.location }
			}} />);
		}
		
		return (
			<div className='App'>
				<div className='AppToolbar triple'>
					<div className='left'>
						<span onClick={ this.goHome } id='siteTitle'>PhoneAndFax.net</span>
					</div>
					<div className='middle'>
						&nbsp;
					</div>
					<div className='right'>
						<NavLink to='/voicemail'>Voicemail</NavLink>
						<NavLink to='/settings'>Settings</NavLink>
						<Link to='/logout'>Logout</Link>
					</div>
				</div>
				<div className='AppBody'>
					<Switch>
						<Route path="/settings" component={ SettingsView } />
						<Route path="/voicemail" component={ VoicemailContainer } />
						<Route path="/greeting/:mailboxID" component={ GreetingsContainer } />
					</Switch>
				</div>
			</div>
		);
	}
}

export default App;
