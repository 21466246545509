export function GetFromStorage(key) {
	let value = sessionStorage.getItem('4sf.' + key);
	if (value === null) {
		value = localStorage.getItem('4sf.' + key);
	}
	return value;
}

export function CurrentToken() {
	return GetFromStorage('token');
}

export function CurrentUserID() {
	return GetFromStorage('user_id');
}

export function CurrentUserEmail() {
	return GetFromStorage('email');
}

export function StoreCredentials(token, user_id, user_email, longterm) {
	ClearCredentials();
	
	if (longterm === true) {
		localStorage.setItem('4sf.token', token);
		localStorage.setItem('4sf.user_id', user_id);
		localStorage.setItem('4sf.email', user_email);
	} else {
		sessionStorage.setItem('4sf.token', token);
		sessionStorage.setItem('4sf.user_id', user_id);
		sessionStorage.setItem('4sf.email', user_email);
	}
}

export function ClearToken() {
	sessionStorage.removeItem('4sf.token');
	localStorage.removeItem('4sf.token');
}

export function ClearCredentials() {
	sessionStorage.removeItem('4sf.token');
	sessionStorage.removeItem('4sf.user_id');
	sessionStorage.removeItem('4sf.email');
	localStorage.removeItem('4sf.token');
	localStorage.removeItem('4sf.user_id');
	localStorage.removeItem('4sf.email');
}

export function CredentialsInLongTerm() {
	let value = localStorage.getItem('4sf.email');
	if (value !== null) {
		return true;
	}
	
	return false;
}

export function FormatCallerName(caller_name) {
	let exp = /^(\d{3})(\d{3})(\d{4})$/;
	let parts = exp.exec(caller_name);
	if (parts !== null) {
		caller_name = '(' + parts[1] + ') ' + parts[2] + '-' + parts[3];
	}
	return caller_name;
}

export function APIBase() {
	let hostname = window.location.hostname;
	if (hostname === 'localhost') {
		hostname = 'dev.app.phoneandfax.net';
	}

	return 'https://' + hostname + '/api/v1';
}