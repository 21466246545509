import React from 'react';
import axios from 'axios';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import TimeAgo from 'react-timeago';
import ReactAudioPlayer from 'react-audio-player';
import { CurrentToken, FormatCallerName, APIBase, ClearCredentials } from './helpers';
import LoadingView from './LoadingView';
import moment from 'moment-timezone';
import { SplitView, SplitViewComponent } from 'SplitView';
import ScrollView from 'ScrollView';

class VoicemailContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			mailboxes: [],
			messages: [],
			activeMailbox: '00000000-0000-0000-0000-000000000000',
			activeMessage: null,
			activeMessageID: null
		};
		
		this.handleMailboxChange = this.handleMailboxChange.bind(this);
		this.handleShowDetail = this.handleShowDetail.bind(this);
		this.handleHideDetail = this.handleHideDetail.bind(this);
		this.getMessageDetail = this.getMessageDetail.bind(this);
		this.removeMessageFromArray = this.removeMessageFromArray.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.handleGreetings = this.handleGreetings.bind(this);
		this.handleLocationChange = this.handleLocationChange.bind(this);
		this.fetchMessages = this.fetchMessages.bind(this);
	}

	handleLocationChange(location) {
		let pattern = /^\/voicemail(\/message\/([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}))?$/i;
		let results = pattern.exec(location.pathname);
		if (results) {
			if (results[2] !== this.state.activeMessageID) {
				let message_id = results[2];
				let message = this.getMessageDetail(message_id);
				if (message) {
					this.setState({activeMessage: message, activeMessageID: message_id});
				} else {
					this.setState({activeMessage: null, activeMessageID: null});
				}
			}
		}
	}

	componentWillMount() {
		this.unlisten = this.props.history.listen((location, action) => {
			this.handleLocationChange(location);
		});
	}

	componentWillUnmount() {
		this.unlisten();
	}
	
	componentDidMount() {
		axios.get(APIBase() + '/messages?token=' + encodeURIComponent(CurrentToken())).then((response) => {
			let mailboxes = response.data.mailboxes;
			let messages = response.data.messages;
			
			this.setState({'loading': false, 'mailboxes': mailboxes, 'messages': messages});
			this.handleLocationChange(this.props.history.location);
		}).catch((error) => {
			this.setState({'loading': false});
			alert('There was a problem loading the list of message: ' + error.message);
		});
	}
	
	handleMailboxChange(event) {
		this.setState({'activeMailbox': event.target.value});
	}
	
	handleShowDetail(detail) {
		this.setState({'activeMessage': detail});
	}
	
	handleHideDetail() {
		this.setState({'activeMessage': null});
	}

	handleLogout(event) {
		ClearCredentials();
		this.props.history.push('/login');
	}

	handleGreetings(event) {
		if (this.state.activeMailbox === '00000000-0000-0000-0000-000000000000') {
			alert('Select a mailbox first');
			return;
		}

		this.props.history.push('/greeting/' + this.state.activeMailbox);
	}
	
	getMessageDetail(message_id) {
		let message = null;
		this.state.messages.some((item) => {
			if (item.message_id === message_id) {
				message = item;
				return true;
			}
			return false;
		});
		return message;
	}
	
	removeMessageFromArray(message_id) {
		let replacement = [];
		this.state.messages.forEach((item) => {
			if (item.message_id !== message_id) {
				replacement.push(item);
			}
		});
		this.setState({'messages': replacement});
	}

	fetchMessages(mailbox_id) {
		if (mailbox_id === '00000000-0000-0000-0000-000000000000') {
			return this.state.messages;
		}

		let messages = [];
		this.state.messages.some((item) => {
			if (item.mailbox_id === mailbox_id) {
				messages.push(item);
			}
			return null;
		});
		return messages;
	}
	
	render() {
		if (this.state.loading === true) {
			return (<LoadingView caption='Downloading messages…' />);
		}

		let ListView;
		if (this.state.mailboxes.length !== 1) {
			ListView = (props) => <MailboxList mailboxes={ this.state.mailboxes } fetchMessagesCallback={ this.fetchMessages } fetchMessageCallback={ this.getMessageDetail } onDelete={ this.removeMessageFromArray } { ...props } />;
		} else {
			ListView = (props) => <VoicemailList title={ this.state.mailboxes[0].name } messages={ this.state.messages } fetchMessageCallback={ this.getMessageDetail } onDelete={ this.removeMessageFromArray } mailboxPathComponent='' { ...props } />;
		}

		return (<SplitView initialListView={ ListView } history={ this.props.history } />);
	}
}

class MailboxList extends SplitViewComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			mailboxes: [{mailbox_id: '00000000-0000-0000-0000-000000000000', name: 'All Mailboxes'}, ...props.mailboxes]
		};

		this.selectMailbox = this.selectMailbox.bind(this);
		this.getMailbox = this.getMailbox.bind(this);
		this.onDelete = props.onDelete;
	}

	selectMailbox(mailbox) {
		let messages = this.props.fetchMessagesCallback(mailbox.mailbox_id);
		let messageList = (props) => <VoicemailList title={ mailbox.name } messages={ messages } withBackButton={ true } fetchMessageCallback={ this.props.fetchMessageCallback } onDelete={ this.onDelete } mailboxPathComponent={ mailbox.mailbox_id } {...props} />
		this.addView(messageList, false);
	}

	getMailbox(mailbox_id) {
		for (let idx = 0; idx < this.state.mailboxes.length; idx++) {
			if (this.state.mailboxes[idx].mailbox_id === mailbox_id) {
				return this.state.mailboxes[idx];
			}
		}
	}

	componentDidMount() {
		if (this.props.history.location.pathname === '/voicemail/mailbox/all') {
			this.selectMailbox('00000000-0000-0000-0000-000000000000');
			return;
		}

		let pattern = /\/voicemail\/mailbox\/([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12})/;
		let results = pattern.exec(this.props.history.location.pathname);
		if (results) {
			let uuid = results[1];
			let mailbox = this.getMailbox(uuid);
			if (mailbox) {
				this.selectMailbox(mailbox);
			}
			return;
		}

		pattern = /\/voicemail\/message\/([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12})/;
		results = pattern.exec(this.props.history.location.pathname);
		if (results) {
			let messages = this.props.fetchMessagesCallback('00000000-0000-0000-0000-000000000000');
			let uuid = results[1];
			for (let idx = 0; idx < messages.length; idx++) {
				if (messages[idx].message_id === uuid) {
					let mailbox = this.getMailbox(messages[idx].mailbox_id);
					if (mailbox) {
						this.selectMailbox(mailbox);
					}
					break;
				}
			}
		}
	}

	render() {
		return (<ScrollView title='Mailboxes'>
			<ListGroup variant='flush'>
				{ this.state.mailboxes.map((item) => <MailboxListItem key={ item.mailbox_id } mailbox={ item } onSelect={ this.selectMailbox } history={ this.props.history } />)}
			</ListGroup>
		</ScrollView>);
	}
}

class MailboxListItem extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			mailbox: props.mailbox
		};

		this.handleClick = this.handleClick.bind(this);
	}

	handleClick() {
		if (this.props.onSelect) {
			this.props.onSelect(this.state.mailbox);
		}

		let urlsuffix = this.state.mailbox.mailbox_id;
		if (urlsuffix === '00000000-0000-0000-0000-000000000000') {
			urlsuffix = 'all';
		}
		
		this.props.history.push('/voicemail/mailbox/' + urlsuffix);
	}

	render() {
		return (<ListGroup.Item onClick={ this.handleClick }>{ this.state.mailbox.name }</ListGroup.Item>);
	}
}

class VoicemailList extends SplitViewComponent {
	constructor(props) {
		super(props);

		this.state = {
			messages: props.messages,
			title: props.title,
			withBackButton: props.withBackButton
		};

		this.showVoicemail = this.showVoicemail.bind(this);
		this.deleteVoicemail = this.deleteVoicemail.bind(this);
		this.navBack = this.navBack.bind(this);
		this.deleteCallback = props.onDelete;
	}

	componentDidMount() {
		let pattern = /\/voicemail\/message\/([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12})/;
		let results = pattern.exec(this.props.history.location.pathname);
		if (results) {
			let uuid = results[1];
			for (let idx = 0; idx < this.state.messages.length; idx++) {
				if (this.state.messages[idx].message_id === uuid) {
					this.showVoicemail(this.state.messages[idx]);
					break;
				}
			}
		}
	}

	showVoicemail(message) {
		let detailView = (props) => <VoicemailMessageView {...props} activeMessage={ message } messageDeletedCallback={ this.deleteVoicemail } mailboxPathComponent={ this.props.mailboxPathComponent } />;
		this.props.history.push('/voicemail/message/' + message.message_id);
		this.addView(detailView, true);
	}

	navBack() {
		this.props.history.push('/voicemail');
		this.navigateBack();
	}

	deleteVoicemail(message_id) {
		if (this.deleteCallback) {
			this.deleteCallback(message_id);
		}

		let replacement = [];
		this.state.messages.forEach((item) => {
			if (item.message_id !== message_id) {
				replacement.push(item);
			}
		});
		this.setState({'messages': replacement});

		this.navBack();
	}

	render() {
		let backButton;
		if (this.state.withBackButton) {
			backButton = this.navBack;
		}

		let contentView;
		if (this.state.messages.length === 0) {
			contentView = <div className='NoContentView'>No Messages</div>;
		} else {
			contentView = <ListGroup variant="flush">
				{this.state.messages.map((item) => <VoicemailListItem key={ item.message_id } messageDetails={ item } onSelected={ this.showVoicemail } active={ this.props.history.location.pathname === '/voicemail/message/' + item.message_id } /> )}
			</ListGroup>;
		}

		return (<ScrollView title={ this.state.title } backButton={ backButton }>{ contentView }</ScrollView>);
	}
}

class VoicemailListItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			messageDetails: props.messageDetails,
			active: props.active
		};
		
		this.handleSelected = this.handleSelected.bind(this);
		
		this.showDetailCallback = props.onSelected;
	}
	
	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			activeMailbox: nextProps.activeMailbox,
			active: nextProps.active
		};
	}
	
	handleSelected(event) {
		this.showDetailCallback(this.state.messageDetails);
	}
	
	render() {
		let caller_name = this.state.messageDetails.caller_name;
		if (caller_name === '') {
			caller_name = this.state.messageDetails.caller_number;
		}
		caller_name = FormatCallerName(caller_name);
		
		let created = Date.parse(this.state.messageDetails.created);
		
		return (<ListGroup.Item onClick={this.handleSelected} active={this.state.active}><div className='voiceListItem'><div className='callerName'>{caller_name}</div><div className='voicemailAge'><TimeAgo date={created} /></div></div></ListGroup.Item>);
	}
}

class VoicemailMessageView extends SplitViewComponent {
	constructor(props) {
		super(props);
		this.state = {
			message: props.activeMessage,
			messageDeletedCallback: props.messageDeletedCallback
		}

		this.shouldDismiss = this.shouldDismiss.bind(this);
		this.deleteVoicemail = this.deleteVoicemail.bind(this);
	}

	shouldDismiss() {
		let pathComponent = this.props.mailboxPathComponent;
		if (pathComponent === '00000000-0000-0000-0000-000000000000') {
			pathComponent = 'all';
		}
		this.props.history.push('/voicemail/mailbox/' + pathComponent);
		this.navigateBack();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return {message: nextProps.activeMessage};
	}

	deleteVoicemail(event) {
		axios.delete(this.state.message.audio + '?token=' + CurrentToken()).then((response) => {
			this.state.messageDeletedCallback(this.state.message.message_id);
			//this.props.history.push('/voicemail');
		}).catch((error) => {
			alert('Sorry, the message was not deleted: ' + error.message);

		});
		
		event.preventDefault();
	}

	render () {
		if (this.state.message === null) {
			return (<ScrollView title='No Message Selected'></ScrollView>);
		}

		let deleteButton = <Button variant='link' className='danger' onClick={ this.deleteVoicemail }>Delete</Button>;

		let caller_name = this.state.message.caller_name;
		if (caller_name === '') {
			caller_name = this.state.message.caller_number;
		}
		caller_name = FormatCallerName(caller_name);

		let transcript = this.state.message.transcript;
		let transcriptClassName = 'transcript';
		if (transcript === '') {
			transcript = 'Transcript not available';
			transcriptClassName += ' missing';
		}

		let created = moment(Date.parse(this.state.message.created));

		let titleComponent = <span className='multiline'>{ caller_name }<br /><span className='small-only sub'>{ created.format('MM/DD/YY h:mm A') }</span><span className='large-only sub'>{ created.format('dddd, MMMM Do YYYY, h:mm:ss A') }</span></span>;

		if (this.state.message) {
			return (<ScrollView titleComponent={ titleComponent } backButton={ this.shouldDismiss } accessoryButton={ deleteButton }>
				<div className='voicemailDetail'>
					<p className={ transcriptClassName }>{ transcript }</p>
					<ReactAudioPlayer ref={(element) => {this.playerRef = element}} src={this.state.message.audio + '?token=' + CurrentToken()} controls onError={ (event) => { console.log('The audio file could not be played') } } />
				</div>
			</ScrollView>);
		} else {
			return (<ScrollView title='No Message Selected'></ScrollView>);
		}
	}
}

export default VoicemailContainer;