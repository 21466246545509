import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

class LoadingView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			caption: props.caption
		};
	}

	static getDerivedStateFromProps(nextProps, prevState){
		if (nextProps.caption !== prevState.caption){
			return { someState: nextProps.caption };
		}
		return null;
	}

	render() {
		let caption = this.state.caption;
		if (caption === '' || caption === null || caption === undefined) {
			caption = 'Loading…';
		}
		return(<div className='loadingContainer'><div className='loadingContent'><Spinner className='mb-1' animation="border" variant="primary" /><br></br>{ caption }</div></div>);
	}
}

export default LoadingView;