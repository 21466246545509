import React from 'react';

class SplitView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			readying: false,
			showDetail: false
		}

		this.addView = this.addView.bind(this);
		this.navigateBack = this.navigateBack.bind(this);

		let views = [];
		if (props.initialListView) {
			views = [props.initialListView];
		}

		this.state = {
			listViews: views,
			detailView: null
		};
	}

	addView(newView, asDetailView) {
		if (!newView) {
			return;
		}

		if (asDetailView === true) {
			let detailView = React.createElement(newView, {
				history: this.props.history,
				addViewCallback: this.addView,
				navBackCallback: this.navigateBack
			});
			this.setState({showDetail: true, 'detailView': detailView});
		} else {
			this.setState({readying: true, listViews: [...this.state.listViews, newView]});
			
			let self = this;
			setTimeout(function() {
				self.setState({readying: false});
			}, 100);
		}
	}

	navigateBack() {
		let isSmall = window.matchMedia('(max-width: 768px)').matches;

		if (isSmall && this.state.detailView) {
			this.setState({showDetail: false});

			let self = this;
			setTimeout(function() {
				self.setState({detailView: null});
			}, 500);
		} else {
			if (this.state.listViews.length === 1) {
				return;
			}

			this.setState({readying: true});

			let self = this;
			setTimeout(function() {
				let views = [...self.state.listViews];
				views.splice(views.length - 1, 1);
				self.setState({listViews: views});
			}, 500);
		}
	}

	render() {
		let readyIndex = -1;
		let focusedIndex = -1;
		let viewCount = this.state.listViews.length;

		if (viewCount >= 2 && this.state.readying) {
			readyIndex = this.state.listViews.length - 1;
			focusedIndex = this.state.listViews.length - 2;
		} else {
			focusedIndex = viewCount - 1;
		}

		let listViews = this.state.listViews.map((view, index) => {
			let statusClass = 'blurred';
			if (index === readyIndex) {
				statusClass = 'ready';
			} else if (index === focusedIndex) {
				statusClass = 'focused';
			}

			let listView = React.createElement(view, {
				history: this.props.history,
				addViewCallback: this.addView,
				navBackCallback: this.navigateBack
			});

			return(<div key={ index } className={`SplitViewList ${ statusClass }`}><listView.type {...listView.props} /></div>);
		});

		return (<div className={`SplitView ${this.state.detailView && this.state.showDetail ? 'MainViewVisible' : ''}`}>
			<div className='SplitViewListContainer'>
				{ listViews }
			</div>
			<div className='SplitViewMain'>
				{ this.state.detailView ? this.state.detailView : null }
			</div>
		</div>);
	}
}

class SplitViewComponent extends React.Component {
	constructor(props) {
		super(props);

		this.addView = props.addViewCallback;
		this.navigateBack = props.navBackCallback;
	}

	render() {
		if (React.Children.count(this.props.children) === 1) {
			return React.Children.only(this.props.children);
		} else {
			return (<div>{ this.props.children }</div>);
		}
	}
}

export default SplitView;
export { SplitView, SplitViewComponent };