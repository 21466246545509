import React from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import LoadingView from './LoadingView';
import { CurrentToken, APIBase } from './helpers';
import './GreetingsContainer.scss';

class GreetingsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mailboxID: props.match.params.mailboxID,
			loading: true
		}

		this.handleFinished = this.handleFinished.bind(this);
	}

	componentDidMount() {
		axios.get(APIBase() + '/' + this.state.mailboxID + '/greetings?token=' + encodeURIComponent(CurrentToken())).then((response) => {
			let mailboxes = response.data.mailboxes;
			let messages = response.data.messages;
			
			this.setState({'loading': false, 'mailboxes': mailboxes, 'messages': messages});
		}).catch((error) => {
			this.setState({'loading': false});
			alert('There was a problem loading the list of message: ' + error.message);
		});
	}

	handleFinished(event) {
		this.props.history.push('/');
	}

	render() {
		if (this.state.loading === true) {
			return (<div className='overlayOuter'>
				<div className='overlayInner'>
					<LoadingView caption='Downloading greetings…' />
				</div>
			</div>);
		}

		return (<div className='overlayOuter'>
			<div className='overlayInner'>
				<div className='headerView triple'>
					<div className='left'><Button variant='link' onClick={ this.handleFinished }>Done</Button></div>
					<div className='middle'>Greeting</div>
					<div className='right'>&nbsp;</div>
				</div>
				<div className='scrollable greetingsBody'>{ this.state.mailboxID }</div>
			</div>
		</div>);
	}
}

export default GreetingsContainer;