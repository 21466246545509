import React from 'react';
import { SplitView, SplitViewComponent } from 'SplitView';
import ScrollView from 'ScrollView';
import ListGroup from 'react-bootstrap/ListGroup';
//import LoadingView from 'LoadingView';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { CurrentToken, APIBase } from '../helpers';
import './index.css';

class SettingsView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			focused: false
		};
		this.handleLocationChange = this.handleLocationChange.bind(this);
	}

	handleLocationChange(location) {
		if (location.pathname.startsWith('/settings')) {
			this.setState({focused: location.pathname !== '/settings'});
		}
	}

	componentWillMount() {
		this.unlisten = this.props.history.listen((location, action) => {
			this.handleLocationChange(location);
		});
	}

	componentWillUnmount() {
		this.unlisten();
	}

	componentDidMount() {
		this.handleLocationChange(this.props.history.location);
	}

	render() {
		return (<SplitView initialListView={ SettingsGroupList } history={ this.props.history } />);
	}
}

class SettingsGroupList extends SplitViewComponent {
	constructor(props) {
		super(props);

		this.showGroundwireView = this.showGroundwireView.bind(this);
	}

	componentDidMount() {
		let path = this.props.history.location.pathname;
		if (path.startsWith('/settings/groundwire')) {
			this.addView(GroundwireView, true);
		}
	}

	showGroundwireView() {
		this.addView(GroundwireView, true);
		this.props.history.push('/settings/groundwire');
	}

	render() {
		let agent = navigator.userAgent || navigator.vendor || window.opera;
		let mobileCaption = null;
		if (/windows phone/i.test(agent) === false && /android/i.test(agent)) {
			mobileCaption = 'Android App';
		} else if (/iPad|iPhone|iPod/.test(agent) && !window.MSStream) {
			mobileCaption = 'iOS App';
		} else {
			mobileCaption = 'Phone App';
		}

		return (<ScrollView title='Settings'>
			<ListGroup variant="flush">
				<SettingsListItem history={ this.props.history } path='/settings/groundwire' caption={ mobileCaption } onClick={ this.showGroundwireView } />
			</ListGroup>
		</ScrollView>);
	}
}

class SettingsListItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: props.state
		}

		this.handleClick = this.handleClick.bind(this);
	}

	handleClick() {
		if (this.props.onClick) {
			this.props.onClick();
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			active: nextProps.active
		};
	}

	render() {
		return (<ListGroup.Item onClick={ this.handleClick } active={ this.state.active }>{ this.props.caption }</ListGroup.Item>);
	}
}

class GroundwireView extends SplitViewComponent {
	constructor(props) {
		super(props);

		this.state = {};

		this.startLinkProcess = this.startLinkProcess.bind(this);
		this.navBack = this.navBack.bind(this);
	}

	startLinkProcess() {
		axios.get(APIBase() + '/groundwire?token=' + CurrentToken()).then((response) => {
			let url = response.data.link;
			window.location.href = url;
		}).catch((error) => {
			alert('Sorry, your setup link could not be generated: ' + error.message);
		});
	}

	navBack() {
		this.navigateBack();
		this.props.history.push('/settings');
	}

	render() {
		const iOSURL = 'https://itunes.apple.com/us/app/acrobits-groundwire/id378503081?mt=8';
		const AndroidURL = 'https://play.google.com/store/apps/details?id=cz.acrobits.softphone.aliengroundwire&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
		const iOSButton = <a className="m-2" href={ iOSURL }><img width="150" alt="Download on the App Store" src="/ios_app_store.svg" /></a>;
		const AndroidButton = <a className="m-2" href={ AndroidURL }><img width="150" alt="Get it on Google Play" src="/google_app_store.svg"/></a>;

		let agent = navigator.userAgent || navigator.vendor || window.opera;
		let downloadStep = null;
		let mobileCaption = null;
		if (/windows phone/i.test(agent) === false && /android/i.test(agent)) {
			downloadStep = <div>
				<p>Tap the &quot;Download&quot; button to automatically open Google Play and download Groundwire. There is a one time cost of $9.99. Please contact us and we’ll reimburse you for the full amount.</p>
				<p className="text-center"><a href={ AndroidURL } className="button">Download</a></p>
			</div>;
			mobileCaption = 'Android App';
		} else if (/iPad|iPhone|iPod/.test(agent) && !window.MSStream) {
			downloadStep = <div>
				<p>Tap the &quot;Download&quot; button to automatically open the App Store and download Groundwire. There is a one time cost of $9.99. Please contact us and we’ll reimburse you for the full amount.</p>
				<p className="text-center"><a href={ iOSURL } className="btn btn-primary">Download</a></p>
			</div>;
			mobileCaption = 'iOS App';
		} else {
			downloadStep = <div>
				<p>Choose a marketplace to download Groundwire.  There is a one time cost of $9.99. Please contact us and we’ll reimburse you for the full amount.</p>
				<p className="text-center">{ iOSButton }{ AndroidButton }</p>
			</div>;
			mobileCaption = 'Phone App';
		}

		return (<ScrollView title={ mobileCaption } backButton={ this.navBack }>
			<div className="groundwire-setup">
				<ol>
					<li>
						<h3>Start Here</h3>
						<p>Use your business phone number as a second line on your mobile phone to make and receive calls using the Groundwire app.</p>
						<p>Follow the steps below to easily setup the app.</p>
					</li>
					<li>
						<h3>Download</h3>
						{ downloadStep }
					</li>
					<li>
						<h3>Setup</h3>
						<p>Tap the &quot;Setup&quot; button to automatically open and setup Groundwire.</p>
						<p className="text-center"><Button onClick={ this.startLinkProcess }>Setup</Button></p>
					</li>
					<li>
						<h3>Save Settings</h3>
						<p>Tap the &quot;Save&quot; button in Groundwire to save your settings.</p>
						<p className="text-center"><img className="groundwire-screenshot" src="/groundwire-save-menu.png" width="375" alt="" /></p>
					</li>
					<li>
						<h3>Test Outbound Calls</h3>
						<p>Use Groundwire to make an outbound call.</p>
					</li>
					<li>
						<h3>Test Inbound Calls</h3>
						<p>Use another phone and call your business phone number to confirm Groundwire rings for incoming calls.</p>
					</li>
					<li>
						<h3>Finished</h3>
						<p>These steps are complete.  You can now use the Groundwire app directly without having to repeat these steps.</p>
					</li>
				</ol>
			</div>
		</ScrollView>);
	}
}

/*class UsersListView extends SplitViewComponent {
	constructor(props) {
		super(props);

		this.state = {
			userList: [],
			loading: true
		};

		this.showUserDetails = this.showUserDetails.bind(this);
		this.navBack = this.navBack.bind(this);
	}

	componentDidMount() {
		let onLoad = function() {
			let list = [{id: '76f9d53f-22fb-4ae1-8c34-ba7e76e115b3', name: 'User A'},{id: 'c723d6f5-429b-4feb-876f-77b5e3b37d70', name: 'User B'}];
			this.setState({loading: false, userList: list});

			let pattern = /\/settings\/users\/([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12})/;
			let results = pattern.exec(this.props.history.location.pathname);
			if (results) {
				let uuid = results[1];
				this.showUserDetails(uuid);
			}
		};

		onLoad = onLoad.bind(this);
		setTimeout(onLoad, 1000);
	}

	showUserDetails(user_id) {
		this.addView((props) => <UserDetailView user_id={ user_id } {...props} />, true);
		this.props.history.push('/settings/users/' + user_id);
	}

	navBack() {
		this.navigateBack();
		this.props.history.push('/settings');
	}

	render() {
		if (this.state.loading) {
			return (<LoadingView caption='Fetching Users…' />);
		}

		let users = this.state.userList.map(user => <SettingsListItem key={ user.id } history={ this.props.history } caption={ user.name } onClick={ () => this.showUserDetails(user.id) } active={ this.props.history.location.pathname === '/settings/users/' + user.id } />);

		return (<ScrollView title='Users' backButton={ this.navBack }>
			<ListGroup variant='flush'>
				{ users }
			</ListGroup>
		</ScrollView>);
	}
}

class NumbersListView extends SplitViewComponent {
	constructor(props) {
		super(props);

		this.state = {
			numberList: [],
			loading: true
		};

		this.navBack = this.navBack.bind(this);
	}

	navBack() {
		this.navigateBack();
		this.props.history.push('/settings');
	}

	render() {
		return (<ScrollView title='Numbers' backButton={ this.navBack }>
			<ListGroup variant='flush'>
				<SettingsListItem history={ this.props.history } caption='(555) 555-5555' />
				<SettingsListItem history={ this.props.history } caption='(555) 555-5554' />
			</ListGroup>
		</ScrollView>);
	}
}

class UserDetailView extends SplitViewComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			user_id: props.user_id,
			user_info: null
		};

		this.navBack = this.navBack.bind(this);
	}

	componentDidMount() {
		let onLoad = function() {
			this.setState({loading: false, user_info: {id: '', name: 'Test UI'}});
		};

		onLoad = onLoad.bind(this);
		setTimeout(onLoad, 2000);
	}

	navBack() {
		this.navigateBack();
		this.props.history.push('/settings/users');
	}

	render() {
		if (this.state.loading) {
			return (<LoadingView caption='Loading User…' />);
		}

		return (<ScrollView title={ this.state.user_info.name } backButton={ this.navBack }>
			{ this.state.user_info.name }
		</ScrollView>);
	}
}*/

export default SettingsView;